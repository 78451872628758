const base = 'production-cost-calculation';
const steps = {
  selectSchedules: 'select-schedules',
  dataEntry: 'data-entry',
  summary: 'summary'
};

export const ProductionCostCalculationPath = {
  base,
  steps,
  create: {
    selectSchedules: `${base}/create/${steps.selectSchedules}`,
    dataEntry: `${base}/create/${steps.dataEntry}`,
    summary: `${base}/create/${steps.summary}`
  },
  edit: {
    selectSchedules: `${base}/edit/${steps.selectSchedules}`,
    dataEntry: `${base}/edit/${steps.dataEntry}`,
    summary: `${base}/edit/${steps.summary}`
  }
};

import React, {useCallback, useMemo} from 'react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {TotalDailyAllowancePerOperatingDayProps} from './TotalDailyAllowancePerOperatingDay.types';
import styles from './TotalDailyAllowancePerOperatingDay.module.scss';

const TotalDailyAllowancePerOperatingDay: React.FC<
  TotalDailyAllowancePerOperatingDayProps
> = ({value, entryId, errors}) => {
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-total-daily-allowance-per-operating-day`;
  const tooltipContent =
    'Enter the daily allowance amount per operating day for this schedule and vehicle.';

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const dailyAllowance =
        validateInputNumber(editedValue, value ? value.toString() : '') ??
        undefined;

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {dailyAllowance}
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (typeof value === 'number' ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="total-daily-allowance-per-operating-day"
        label="Total daily allowance/operating day*"
        value={formattedValue}
        tooltipContent={tooltipContent}
        valid={!errors}
        infoError={errors?.optionality ?? errors?.nullable}
        handleInputChange={handleInputChange}
      />
    </span>
  );
};

export default React.memo(TotalDailyAllowancePerOperatingDay);

import {useState, useCallback} from 'react';
import {useLazyQuery} from '@apollo/client';
import SEARCH_PLANR_SCHEDULES from 'Pages/ProductionCost/graphql/searchPlanrSchedulesQuery';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {receivedPlanrSchedules} from 'Pages/ProductionCost/store/slice';
import {
  PlanrScheduleStatus,
  PlanrScheduleType,
  WorkflowType
} from 'Pages/ProductionCost/store/types';

export interface SearchPlanrSchedulesInput {
  lineUuid: string;
  startDate: string;
  endDate: string;
  scheduleStatus?: PlanrScheduleStatus | null;
  scheduleType?: PlanrScheduleType | null;
  scheduleWorkflowType?: WorkflowType | null;
}

const useSearchPlanrSchedules = () => {
  const [isSchedulesLoading, setIsSchedulesLoading] = useState(false);
  const [searchPlanrSchedules] = useLazyQuery(SEARCH_PLANR_SCHEDULES);
  const dispatch = useAppDispatch();

  const searchSchedules = useCallback(
    async (
      lineScheduleKey: number,
      lineSchedule: SearchPlanrSchedulesInput
    ) => {
      setIsSchedulesLoading(true);

      const {data, error} = await searchPlanrSchedules({
        variables: lineSchedule
      });

      setIsSchedulesLoading(false);

      if (error) {
        console.error(error);
        return;
      }

      dispatch(
        receivedPlanrSchedules({
          lineScheduleKey,
          schedules: data?.searchPlanrSchedules ?? []
        })
      );
    },
    [searchPlanrSchedules, dispatch]
  );

  return {searchSchedules, isSchedulesLoading};
};

export default useSearchPlanrSchedules;

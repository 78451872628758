import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router';
import {
  Button,
  Heading,
  Popup,
  PopupSection,
  Tooltip,
  Text
} from '@flixbus/honeycomb-react';
import {Icon, IconArrowRight} from '@flixbus/honeycomb-icons-react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {
  filteredPlanrBusPartnerIds,
  filteredPlanrVehicleIds,
  updateFilterType
} from 'Pages/ProductionCost/store/slice';
import {createProductionCost} from 'Pages/ProductionCost/store/thunks';
import {ProductionCostFilterType} from 'Pages/ProductionCost/store/types';
import {ProductionCostFilterTypePopupProps} from './index.types';
import AllVehiclesFilter from './AllVehiclesFilter/AllVehiclesFilter';
import PartnersFilter from './PartnersFilter/PartnersFilter';
import VehiclesFilter from './VehiclesFilter/VehiclesFilter';
import FilterTypeAccordion from './FilterTypeAccordion/FilterTypeAccordion';
import style from './index.module.scss';
import {ProductionCostCalculationPath} from 'Pages/ProductionCost/constants/routePaths';

const ProductionCostFilterTypePopup: React.FC<
  ProductionCostFilterTypePopupProps
> = ({popupActive, setPopupActive}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [disableButton, setDisableButton] = useState(false);

  const filterTypeState = useAppSelector(
    ({productionCost}) =>
      productionCost.filterType ?? ProductionCostFilterType.AllVehicles
  );
  const [selectedFilterType, setSelectedFilterType] = useState(filterTypeState);

  const selectedPartnerIdsState = useAppSelector(
    ({productionCost}) => productionCost.filteredPlanrBusPartnerIds
  );
  const [selectedPartnerIds, setSelectedPartnerIds] = useState(
    selectedPartnerIdsState
  );

  const selectedVehicleIdsState = useAppSelector(
    ({productionCost}) => productionCost.filteredPlanrVehicleIds
  );
  const [selectedVehicleIds, setSelectedVehicleIds] = useState(
    selectedVehicleIdsState
  );

  const filterTypes = useMemo(
    () => [
      {
        label: 'Calculate with all vehicles',
        filterType: ProductionCostFilterType.AllVehicles,
        children: <AllVehiclesFilter />
      },
      {
        label: 'Restrict calculation to certain bus partner(s)',
        filterType: ProductionCostFilterType.PlanrBusPartners,
        children: (
          <PartnersFilter
            selectedPartnerIds={selectedPartnerIds}
            setSelectedPartnerIds={setSelectedPartnerIds}
          />
        )
      },
      {
        label: 'Restrict calculation to certain vehicle(s)',
        filterType: ProductionCostFilterType.PlanrVehicles,
        children: (
          <VehiclesFilter
            selectedVehicleIds={selectedVehicleIds}
            setSelectedVehicleIds={setSelectedVehicleIds}
          />
        )
      }
    ],
    [selectedPartnerIds, selectedVehicleIds]
  );

  useEffect(() => {
    const disabledButton =
      (selectedFilterType === ProductionCostFilterType.PlanrBusPartners &&
        selectedPartnerIds.length === 0) ||
      (selectedFilterType === ProductionCostFilterType.PlanrVehicles &&
        selectedVehicleIds.length === 0);
    setDisableButton(disabledButton);
  }, [selectedFilterType, selectedPartnerIds, selectedVehicleIds]);

  const handleFilterTypeChange = useCallback(
    (filterType: ProductionCostFilterType) => {
      // reset previously selected ids
      switch (filterType) {
        case ProductionCostFilterType.AllVehicles:
          setSelectedPartnerIds([]);
          setSelectedVehicleIds([]);
          break;
        case ProductionCostFilterType.PlanrBusPartners:
          setSelectedVehicleIds([]);
          break;
        case ProductionCostFilterType.PlanrVehicles:
          setSelectedPartnerIds([]);
          break;
      }

      setSelectedFilterType(filterType);
    },
    []
  );

  const handleNext = async () => {
    setDisableButton(true);
    dispatch(updateFilterType(selectedFilterType));

    let selectedFilterIds: string[] = [];
    if (ProductionCostFilterType.PlanrBusPartners === selectedFilterType) {
      selectedFilterIds = selectedPartnerIds;
      dispatch(filteredPlanrBusPartnerIds(selectedFilterIds));
    } else if (ProductionCostFilterType.PlanrVehicles === selectedFilterType) {
      selectedFilterIds = selectedVehicleIds;
      dispatch(filteredPlanrVehicleIds(selectedFilterIds));
    }

    dispatch(
      createProductionCost(selectedFilterType, selectedFilterIds, () =>
        navigate(`../${ProductionCostCalculationPath.steps.dataEntry}`)
      )
    );
  };

  return (
    <Popup
      aria-labelledby="accessible-popup-title"
      active={popupActive}
      extraClasses="popup-no-content-alignment"
    >
      <PopupSection type="title">
        <Heading id="accessible-popup-title" size={2}>
          Define scope of the calculation
        </Heading>
      </PopupSection>

      <PopupSection type="content" extraClasses="popup-content">
        <Text className={style.subtitle}>
          By default, <b>all vehicles</b> included in the selected Planr
          schedule(s) are considered. Alternatively you can restrict the scope
          to certain bus partners or vehicles.
        </Text>

        {filterTypes.map(({label, filterType, children}) => (
          <FilterTypeAccordion
            key={filterType}
            label={label}
            filterType={filterType}
            selectedFilterType={selectedFilterType}
            onFilterTypeChange={handleFilterTypeChange}
          >
            {children}
          </FilterTypeAccordion>
        ))}
      </PopupSection>

      <PopupSection type="actions">
        <Button
          id="accessible-popup-close"
          appearance="secondary"
          onClick={() => setPopupActive(false)}
        >
          Cancel
        </Button>

        <Tooltip
          id="filter-type-button-popup"
          content="Please select the bus partners or vehicles for which you want to create a calculation"
          position="top"
          openOnHover={disableButton}
        >
          <span>
            <Button
              appearance="primary"
              onClick={handleNext}
              disabled={disableButton}
              data-action-name="Calculation scope Next button"
            >
              Next
              <Icon InlineIcon={IconArrowRight} aria-hidden="true" />
            </Button>
          </span>
        </Tooltip>
      </PopupSection>
    </Popup>
  );
};

export default React.memo(ProductionCostFilterTypePopup);

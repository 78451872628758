export const POWER_BI_REPORT_REGIONAL_YOY_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=07807caf-5479-48ee-8778-5a03f0e74c70&reportObjectId=79952b11-2b45-4c5b-98bc-73d8778d1fdd&ctid=d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5&reportPage=ReportSection2de4d5432ad046c5196c';

export const POWER_BI_REPORT_BUS_PARTNER_YOY_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=07807caf-5479-48ee-8778-5a03f0e74c70&reportObjectId=79952b11-2b45-4c5b-98bc-73d8778d1fdd&ctid=d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5&reportPage=ReportSection2de4d5432ad046c5196c';

export const POWER_BI_REPORT_BUS_PARTNER_COMPARE_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=07807caf-5479-48ee-8778-5a03f0e74c70&reportObjectId=79952b11-2b45-4c5b-98bc-73d8778d1fdd&ctid=d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5&reportPage=eba7af20ae4e8523c75a&pbi_source=appShareLink&portalSessionId=8f3775c9-9356-46a0-8968-487fe6d251c6';

export const POWER_BI_REPORT_HOME_SHOW_VISUALISATION_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=07807caf-5479-48ee-8778-5a03f0e74c70&reportObjectId=b989a698-d217-4e59-b5b9-c97667361f3a&ctid=d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5&reportPage=ReportSection&pbi_source=appShareLink&portalSessionId=8f3775c9-9356-46a0-8968-487fe6d251c6';

export const POWER_BI_REPORT_HOME_SHOW_BREAKDOWN_VISUALISATION_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=07807caf-5479-48ee-8778-5a03f0e74c70&reportObjectId=b989a698-d217-4e59-b5b9-c97667361f3a&ctid=d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5&reportPage=ReportSection3aa515bc482b9964708b&pbi_source=appShareLink&portalSessionId=8f3775c9-9356-46a0-8968-487fe6d251c6';

import React, {useMemo, useCallback} from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Select,
  SelectGroup,
  SelectOption,
  Tag
} from '@flixbus/honeycomb-react';
import {
  Icon,
  IconArrowDownL,
  IconArrowUpL,
  IconCheckmarkStrongSolid,
  IconClose,
  IconCrossedSolid,
  IconReset
} from '@flixbus/honeycomb-icons-react';
import {
  deleteLineSchedule,
  productionCostLineScheduleUpdate,
  toggleLineSchedule
} from '../../../store/slice';
import LineAutocompleteField from '../LineAutocompleteField';
import {
  productionCostWorkflowTypes,
  productionCostLineStatuses,
  planrScheduleTypes
} from '../../../constants/statuses';
import {isNil} from 'ramda';
import {getSchedulesListPerDays} from './LineSchedulesSelect.utils';
import LineSchedulesSelectSummary from '../LineSchedulesSelectSummary/LineSchedulesSelectSummary';
import LineSchedulesTable from '../LineSchedulesSelectTable/LineSchedulesSelectTable';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {
  PlanrLineSchedule,
  PlanrScheduleStatus,
  PlanrScheduleType,
  WorkflowType
} from 'Pages/ProductionCost/store/types';
import useSearchPlanrSchedules from '../hooks/useSearchPlanrSchedules';
import {formateProductionCostDate} from 'Pages/ProductionCost/store/thunks';
import {LineSchedulesSelectProps} from './LineSchedulesSelect.types';

const LineSchedulesSelect: React.FC<LineSchedulesSelectProps> = ({
  lineSchedule,
  lineScheduleKey
}) => {
  const {searchSchedules, isSchedulesLoading} = useSearchPlanrSchedules();

  const startDate = useAppSelector(({productionCost}) =>
    productionCost.startDate !== null
      ? new Date(productionCost.startDate)
      : null
  );
  const endDate = useAppSelector(({productionCost}) =>
    productionCost.endDate !== null ? new Date(productionCost.endDate) : null
  );
  const dispatch = useAppDispatch();

  const schedulesListPerDay = useMemo(
    () =>
      startDate && endDate
        ? getSchedulesListPerDays(startDate, endDate, lineSchedule.schedules)
        : {},
    [startDate, endDate, lineSchedule.schedules]
  );

  const resetTable = useMemo(() => ({schedules: [], isValid: false}), []);

  const updateLineSchedule = useCallback(
    (lineScheduleKey: number, lineUpdates: Partial<PlanrLineSchedule>) => {
      dispatch(
        productionCostLineScheduleUpdate({
          lineScheduleKey,
          lineSchedule: lineUpdates
        })
      );

      const uuidExists = lineUpdates.lineUuid || lineSchedule.lineUuid;
      const filterWasChanged =
        lineSchedule.lineUuid !== lineUpdates.lineUuid ||
        lineSchedule.scheduleStatus !== lineUpdates.scheduleStatus ||
        lineSchedule.scheduleType !== lineUpdates.scheduleType ||
        lineSchedule.scheduleWorkflowType !== lineUpdates.scheduleWorkflowType;

      if (startDate && endDate && uuidExists && filterWasChanged) {
        searchSchedules(lineScheduleKey, {
          lineUuid: (lineUpdates.lineUuid ?? lineSchedule.lineUuid) as string,
          scheduleStatus:
            lineUpdates.scheduleStatus ?? lineSchedule.scheduleStatus,
          scheduleType: lineUpdates.scheduleType ?? lineSchedule.scheduleType,
          scheduleWorkflowType:
            lineUpdates.scheduleWorkflowType ??
            lineSchedule.scheduleWorkflowType,

          startDate: formateProductionCostDate(startDate),
          endDate: formateProductionCostDate(endDate)
        });
      }
    },
    [
      lineSchedule.lineUuid,
      lineSchedule.scheduleStatus,
      lineSchedule.scheduleType,
      lineSchedule.scheduleWorkflowType,
      startDate,
      endDate,
      searchSchedules,
      dispatch
    ]
  );

  const onScheduleTypeChange = useCallback(
    (scheduleType: PlanrScheduleType) => {
      updateLineSchedule(lineScheduleKey, {
        scheduleType,
        ...resetTable
      });
    },
    [lineScheduleKey, resetTable, updateLineSchedule]
  );

  const typeOptions = useMemo(
    () =>
      planrScheduleTypes.map(type => ({
        key: `schedule-type-select-key-${lineScheduleKey}-${type.name}`,
        id: `schedule-type-select-${lineScheduleKey}-${type.name}`,
        value: type.key,
        name: `planrScheduleType-${lineScheduleKey}`,
        label: type.name,
        checked: type.key === lineSchedule.scheduleType,
        onChange: () => onScheduleTypeChange(type.key)
      })),
    [lineScheduleKey, lineSchedule.scheduleType, onScheduleTypeChange]
  );

  if (isNil(startDate) || isNil(endDate)) {
    return null;
  }

  if (!lineSchedule.toggle) {
    return (
      <div className={`production-cost-line-schedule-select`}>
        <Box>
          <div className={`production-cost-line-schedule-actions`}>
            <ButtonGroup alignment="end">
              <Button
                display="square"
                aria-label="Open"
                onClick={() => dispatch(toggleLineSchedule(lineScheduleKey))}
              >
                <Icon InlineIcon={IconArrowDownL} aria-hidden="true" />
              </Button>
              <Button
                display="square"
                aria-label="Delete"
                onClick={() => dispatch(deleteLineSchedule(lineScheduleKey))}
              >
                <Icon InlineIcon={IconClose} aria-hidden="true" />
              </Button>
            </ButtonGroup>
          </div>
          <div className={`production-cost-line-schedule-short`}>
            <div>
              {!lineSchedule.isValid ? (
                <Icon
                  InlineIcon={IconCrossedSolid}
                  style={{fill: 'red'}}
                  size={4}
                />
              ) : (
                <Icon
                  InlineIcon={IconCheckmarkStrongSolid}
                  style={{fill: 'green'}}
                  size={4}
                />
              )}
            </div>
            <div>
              <Tag>Line {lineSchedule.lineNumber}</Tag>
            </div>
            <div>
              <strong>Selected:</strong>{' '}
              {lineSchedule.schedules
                .filter(schedule => schedule.selected)
                .map(schedule => schedule.name)
                .join(', ')}
            </div>
          </div>
        </Box>
      </div>
    );
  }

  return (
    <div className={`production-cost-line-schedule-select`}>
      <Box
        key={`production-cost-line-schedule-select-box-key-${lineScheduleKey}`}
      >
        <LineSchedulesSelectSummary schedulesListPerDay={schedulesListPerDay} />

        <div className={`production-cost-line-schedule-actions`}>
          <ButtonGroup alignment="end">
            <Button
              display="square"
              aria-label="Close"
              onClick={() => dispatch(toggleLineSchedule(lineScheduleKey))}
            >
              <Icon InlineIcon={IconArrowUpL} aria-hidden="true" />
            </Button>
            <Button
              display="square"
              aria-label="Delete"
              onClick={() => dispatch(deleteLineSchedule(lineScheduleKey))}
            >
              <Icon InlineIcon={IconClose} aria-hidden="true" />
            </Button>
          </ButtonGroup>
        </div>

        <div>
          <h2>Select schedules for calculation</h2>
        </div>
        <div className={`production-cost-line-schedule-filter`}>
          <div className={`production-cost-line-schedule-field`}>
            <LineAutocompleteField
              lineScheduleKey={lineScheduleKey}
              line={lineSchedule.lineNumber}
              onSelected={item =>
                updateLineSchedule(lineScheduleKey, {
                  lineNumber: item.title,
                  lineUuid: item.value,
                  ...resetTable
                })
              }
              valid
            />
          </div>
          <div className={`production-cost-line-schedule-field`}>
            <Select
              id={`calculation-status-${lineScheduleKey}`}
              key={`calculation-status-key-${lineScheduleKey}`}
              label="PlanR schedule status"
              placeholder="Select PlanR schedule status"
              value={lineSchedule.scheduleStatus || ''}
              onChange={e => {
                updateLineSchedule(lineScheduleKey, {
                  scheduleStatus: e.target.value as PlanrScheduleStatus,
                  ...resetTable
                });
              }}
              disabled={!lineSchedule.lineNumber}
            >
              {productionCostLineStatuses.map(status => (
                <SelectOption key={status.key} value={status.key}>
                  {status.name}
                </SelectOption>
              ))}
            </Select>
          </div>
          <div className={`production-cost-line-schedule-field`}>
            <Select
              id={`calculation-workflow-type-${lineScheduleKey}`}
              key={`calculation-workflow-type-key-${lineScheduleKey}`}
              label="PlanR workflow type"
              placeholder="Select PlanR workflow type"
              value={lineSchedule.scheduleWorkflowType ?? ''}
              onChange={e => {
                updateLineSchedule(lineScheduleKey, {
                  scheduleWorkflowType: e.target.value as WorkflowType,
                  ...resetTable
                });
              }}
              disabled={!lineSchedule.lineNumber}
            >
              {productionCostWorkflowTypes.map(type => (
                <SelectOption key={type.key} value={type.key}>
                  {type.name}
                </SelectOption>
              ))}
            </Select>
          </div>
          <div className={`production-cost-line-schedule-field`}>
            <SelectGroup
              key={`schedule-type-key-${lineScheduleKey}`}
              aria-label="Schedule type"
              label="Schedule type"
              options={typeOptions}
              id={`schedule-type-${lineScheduleKey}`}
              disabled={!lineSchedule.lineNumber}
            />
          </div>
          <div className={`production-cost-line-schedule-field`}>
            <Button
              style={{marginTop: '24px'}}
              display="square"
              aria-label="Reset filters"
              onClick={() =>
                updateLineSchedule(lineScheduleKey, {
                  scheduleStatus: null,
                  scheduleType: null,
                  ...resetTable
                })
              }
              disabled={!lineSchedule.lineNumber}
            >
              <Icon InlineIcon={IconReset} aria-hidden="true" />
            </Button>
          </div>
        </div>

        <LineSchedulesTable
          lineSchedule={lineSchedule}
          lineScheduleKey={lineScheduleKey}
          isSchedulesLoading={isSchedulesLoading}
          schedulesListPerDay={schedulesListPerDay}
        />
      </Box>
    </div>
  );
};

export default React.memo(LineSchedulesSelect);

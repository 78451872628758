import React from 'react';
import {Text} from '@flixbus/honeycomb-react';
import {ReadOnlyInputProps} from './ReadOnlyInput.types';
import styles from './ReadOnlyInput.module.scss';

const ReadOnlyInput: React.FC<ReadOnlyInputProps> = ({label, value, id}) => {
  return (
    <span className={styles.container}>
      <Text Elem="div" aria-label={`${id}-label`}>
        {label}
      </Text>
      <Text aria-label={`${id}-value`}>
        <b>{value}</b>
      </Text>
    </span>
  );
};

export default React.memo(ReadOnlyInput);

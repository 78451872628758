import React from 'react';
import {Outlet} from 'react-router';
import styles from './index.module.scss';

const ProductionCost: React.FC = () => {
  return (
    <div className={styles.container}>
      <Outlet />
    </div>
  );
};
export default React.memo(ProductionCost);

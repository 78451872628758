import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import {
  Fieldset,
  Grid,
  GridCol,
  Input,
  Legend,
  Radio,
  Select,
  SelectOption
} from '@flixbus/honeycomb-react';
import {defaultTo, isNil} from 'ramda';
import {saveVehicleType} from '../store/thunks';
import {resetVehicleType, setVehicleType} from '../store/slice';
import ContentLoader from '../../Common/ContentLoader';
import {isBlank, isNotNilOrEmpty} from 'ramda-adjunct';
import CpcPanel from '../../Common/CpcPanel';
import {useLocalUnitsContext} from '../../Common/LocalUnits';
import {validateInputNumber} from '../../Common/Utils';
import {VehicleFuelType} from '../store/types';

const VehicleTypePanel = ({
  vehicleType,
  setVehicleType,
  resetVehicleType,
  saveVehicleType
}) => {
  const {localUnits} = useLocalUnitsContext();
  const [showPanel, setShowPanel] = useState(false);
  const [valid, setValid] = useState(null);
  const isEditVehicleType = isNotNilOrEmpty(vehicleType?.id);
  const valueOrEmptyString = defaultTo('');

  useEffect(() => {
    setShowPanel(!isNil(vehicleType));
  }, [vehicleType]);

  const closePanel = () => {
    setShowPanel(false);
    setValid(null);
    resetVehicleType();
  };

  const handleSaveVehicleType = () => {
    if (isBlank(vehicleType.name) || isBlank(vehicleType.numberOfTires)) {
      setValid(false);
    } else {
      saveVehicleType();
    }
  };

  const onChangeInput = (changeEvent, field) => {
    const {value} = changeEvent.target;
    const integerDigitsCount = 6;
    const fractionDigitsCount = field === 'numberOfTires' ? 0 : 2;
    const newValue = validateInputNumber(
      value,
      vehicleType[field],
      integerDigitsCount,
      fractionDigitsCount
    );

    setVehicleType({
      ...vehicleType,
      [field]: newValue ?? null
    });
  };

  return (
    <CpcPanel
      title={isEditVehicleType ? 'Edit vehicle type' : 'Add vehicle type'}
      activeAction={showPanel}
      closeAction={closePanel}
      saveAction={handleSaveVehicleType}
    >
      {!isNil(vehicleType) ? (
        <>
          <Grid>
            <GridCol size={12}>
              <Input
                name="vehicle-type-name"
                id="vehicle-type-name"
                label="Manufacturer & Model"
                valid={
                  valid === null ? null : valid || !isBlank(vehicleType.name)
                }
                value={vehicleType.name}
                onChange={e =>
                  setVehicleType({...vehicleType, name: e.target.value})
                }
              />
            </GridCol>
          </Grid>
          <Grid>
            <GridCol size={12}>
              <div className={`hcr-space-4-top`}>
                <Select
                  name="vehicle-type-fuel-type"
                  id="vehicle-type-fuel-type"
                  label="Fuel type"
                  value={vehicleType.fuelType ?? ''}
                  onChange={e =>
                    setVehicleType({...vehicleType, fuelType: e.target.value})
                  }
                >
                  <SelectOption value={VehicleFuelType.DIESEL}>
                    Diesel
                  </SelectOption>
                  <SelectOption value={VehicleFuelType.LNG}>LNG</SelectOption>
                </Select>
              </div>
            </GridCol>
          </Grid>
          {vehicleType.fuelType === VehicleFuelType.DIESEL && (
            <>
              <Grid>
                <GridCol size={12}>
                  <div className={`hcr-space-4-top`}>
                    <Input
                      type={`number`}
                      name="vehicle-type-diesel-consumption"
                      id="vehicle-type-diesel-consumption"
                      label={`Diesel Consumption (${localUnits.dieselConsumption})`}
                      placeholder="0.0"
                      value={valueOrEmptyString(vehicleType.dieselConsumption)}
                      onChange={event =>
                        onChangeInput(event, 'dieselConsumption')
                      }
                      step={`0.1`}
                    />
                  </div>
                </GridCol>
              </Grid>
              <Grid>
                <GridCol size={12}>
                  <div className={`hcr-space-4-top`}>
                    <Input
                      type={`number`}
                      name="vehicle-type-ad-blue-consumption"
                      id="vehicle-type-ad-blue-consumption"
                      label={`Adblue Consumption (${localUnits.adBlueConsumption})`}
                      placeholder="0.0"
                      value={valueOrEmptyString(vehicleType.adblueConsumption)}
                      onChange={event =>
                        onChangeInput(event, 'adblueConsumption')
                      }
                      step={`0.1`}
                    />
                  </div>
                </GridCol>
              </Grid>
            </>
          )}
          {vehicleType.fuelType === VehicleFuelType.LNG && (
            <Grid>
              <GridCol size={12}>
                <div className={`hcr-space-4-top`}>
                  <Input
                    type={`number`}
                    name="vehicle-type-lng-consumption"
                    id="vehicle-type-lng-consumption"
                    label={`LNG Consumption (${localUnits.lngConsumption})`}
                    placeholder="0.0"
                    value={valueOrEmptyString(vehicleType.lngConsumption)}
                    onChange={event => onChangeInput(event, 'lngConsumption')}
                    step={`0.1`}
                  />
                </div>
              </GridCol>
            </Grid>
          )}
          <Grid>
            <GridCol size={6}>
              <div className={`hcr-space-4-top`}>
                <Input
                  type="number"
                  name="vehicle-type-tires"
                  id="vehicle-type-tires"
                  label="No. of tires"
                  valid={
                    valid === null
                      ? null
                      : valid || !isBlank(vehicleType.numberOfTires)
                  }
                  placeholder="4"
                  value={valueOrEmptyString(vehicleType.numberOfTires)}
                  onChange={event => onChangeInput(event, 'numberOfTires')}
                />
              </div>
            </GridCol>
          </Grid>
          <Grid>
            <GridCol size={12}>
              <div className={`hcr-space-4-top`}>
                <Fieldset>
                  <Legend>SS/DD</Legend>
                  <Radio
                    label="Single decker"
                    id="vehicle-type-single-decker"
                    name="vehicle-type-decker"
                    value={vehicleType.doubleDecker === false ? 1 : 0}
                    key="vehicle-type-single-decker"
                    onClick={() =>
                      setVehicleType({
                        ...vehicleType,
                        doubleDecker: false
                      })
                    }
                    defaultChecked={vehicleType.doubleDecker === false}
                  />
                  <Radio
                    label="Double decker"
                    id="vehicle-type-double-decker"
                    name="vehicle-type-decker"
                    value={vehicleType.doubleDecker === true ? 1 : 0}
                    key="vehicle-type-double-decker"
                    onClick={() =>
                      setVehicleType({
                        ...vehicleType,
                        doubleDecker: true
                      })
                    }
                    defaultChecked={vehicleType.doubleDecker === true}
                  />
                </Fieldset>
              </div>
            </GridCol>
          </Grid>
        </>
      ) : (
        <ContentLoader />
      )}
    </CpcPanel>
  );
};

export default connect(
  state => ({
    vehicleType: state.vehicleTypes.vehicleType
  }),
  {setVehicleType, resetVehicleType, saveVehicleType}
)(VehicleTypePanel);

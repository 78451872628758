import React from 'react';
import {Text} from '@flixbus/honeycomb-react';
import {CpcIconAttention} from 'Pages/Common/CpcIcons';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {CalculationUserJourneyMode} from 'Pages/ProductionCost/store/types';
import {getPlanningPeriodDate} from '../../../utils/formatePlanningPeriodDate';
import {productionCostStatuses} from '../../../constants/statuses';
import styles from './ProductionCostHeaderReadOnly.module.scss';

const ProductionCostHeaderReadOnly: React.FC = () => {
  const startDate = useAppSelector(
    ({productionCost}) => productionCost.startDate
  );
  const endDate = useAppSelector(({productionCost}) => productionCost.endDate);
  const status = useAppSelector(({productionCost}) => productionCost.status);
  const name = useAppSelector(({productionCost}) => productionCost.name);
  const costYear = useAppSelector(
    ({productionCost}) => productionCost.costYear
  );
  const calculationUserJourneyMode = useAppSelector(
    ({productionCost}) => productionCost.calculationUserJourneyMode
  );

  const statusName =
    productionCostStatuses.find(({key}) => key === status)?.name ?? '';

  return (
    <div className={styles.container}>
      <div className={styles.field}>
        <Text Elem="span" extraClasses="content-secondary-color">
          Planning period
        </Text>
        <Text extraClasses="font-bold">
          {getPlanningPeriodDate(startDate, endDate)}
        </Text>
      </div>

      <div className={styles.field}>
        <Text Elem="span" extraClasses="content-secondary-color">
          Calculation status
        </Text>
        <Text extraClasses="font-bold">{statusName ?? ''}</Text>
      </div>

      <div className={styles.field}>
        <Text Elem="span" extraClasses="content-secondary-color">
          Calculation name
        </Text>
        <Text extraClasses="font-bold">{name ?? ''}</Text>
      </div>

      <div className={styles.field}>
        <Text Elem="span" extraClasses="content-secondary-color">
          Year of cost information
        </Text>
        <Text extraClasses="font-bold">{costYear ?? ''}</Text>
      </div>

      {calculationUserJourneyMode ===
        CalculationUserJourneyMode.EditCalculation && (
        <div className={styles.warningMessage}>
          <CpcIconAttention /> Schedule data is not updated when you edit a
          calculation.
        </div>
      )}
    </div>
  );
};

export default React.memo(ProductionCostHeaderReadOnly);

import React from 'react';
import {Outlet} from 'react-router';
import AppHeader from './AppHeader';

const AppLayout: React.FC<{displayNavigation: boolean}> = ({
  displayNavigation
}) => (
  <>
    <AppHeader displayNavigation={displayNavigation} />
    <Outlet />
  </>
);

export default React.memo(AppLayout);

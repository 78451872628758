import React, {useEffect, useState} from 'react';
import {Icon, IconRulerSolid} from '@flixbus/honeycomb-icons-react';
import {Select, SelectOption} from '@flixbus/honeycomb-react';
import {useLocation} from 'react-router';
import {getUnitsSystemOrDefault, UnitsSystems} from './Utils';
import {useLocalUnitsContext} from './useLocalUnitsContext';
import styles from './LocalUnits.module.scss';

const LocalUnitsComp: React.FC = () => {
  const {localUnits, setLocalUnits} = useLocalUnitsContext();
  const [isLocalUnitDisabled, setLocalUnitDisabled] = useState<boolean>();
  const location = useLocation();

  useEffect(() => {
    location.pathname === '/'
      ? setLocalUnitDisabled(false)
      : setLocalUnitDisabled(true);
  }, [location]);

  const handleUnitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const unitsSystemSelected: string = event.target.value;
    setLocalUnits(getUnitsSystemOrDefault(unitsSystemSelected));
  };

  const getUnitsSystemOptions = () => {
    return UnitsSystems.map(unitsSystem => (
      <SelectOption
        key={unitsSystem.unitsSystem}
        value={unitsSystem.unitsSystem}
      >
        {unitsSystem.name}
      </SelectOption>
    ));
  };

  return (
    <div className={styles.container}>
      <Icon InlineIcon={IconRulerSolid} size={6} />
      <Select
        id="local-units-select"
        aria-label="Select local units"
        value={localUnits?.unitsSystem}
        onChange={handleUnitChange}
        disabled={isLocalUnitDisabled}
      >
        {getUnitsSystemOptions()}
      </Select>
    </div>
  );
};

export const LocalUnits = React.memo(LocalUnitsComp);

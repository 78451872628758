import React, {useMemo} from 'react';
import {useLocation} from 'react-router';
import {
  Header as HoneycomHeader,
  HeaderBrand,
  HeaderWidgets,
  HeaderNavigation,
  NavItem
} from '@flixbus/honeycomb-react';
import {LoginWidget} from 'Pages/Auth';
import {LocalUnits} from 'Pages/Common/LocalUnits';
import {PartnersPath} from 'Pages/Partners/constants/routePaths';
import {PartnerCostsPath} from 'Pages/PartnerCosts/constants/routePaths';
import {RegionalCostsPath} from 'Pages/RegionalCosts/constants/routePaths';
import {VehicleTypesPath} from 'Pages/VehicleTypes/constants/routePaths';
import {CountriesPath} from 'Pages/Countries/constant/routePaths';
import {isDevEnvironment, isStagingEnvironment} from 'Pages/Common/Utils';
import cpcLogoProd from '../assets/cpc-logo-prod.svg';
import cpcLogoLocal from '../assets/cpc-logo-local.svg';
import cpcLogoStaging from '../assets/cpc-logo-staging.svg';

const AppHeader: React.FC<{displayNavigation: boolean}> = ({
  displayNavigation
}) => {
  const logo = isDevEnvironment
    ? cpcLogoLocal
    : isStagingEnvironment
    ? cpcLogoStaging
    : cpcLogoProd;

  const {pathname} = useLocation();
  const navItems = useMemo(() => {
    const isActive = (path: string) => pathname.includes(path);
    return [
      {
        label: 'Partners',
        href: `/${PartnersPath.base}`,
        active: isActive(PartnersPath.base)
      },
      {
        label: 'Partner costs',
        href: `/${PartnerCostsPath.base}`,
        active: isActive(PartnerCostsPath.base)
      },
      {
        label: 'Regional costs',
        href: `/${RegionalCostsPath.base}`,
        active: isActive(RegionalCostsPath.base)
      },
      {
        label: 'Vehicle types',
        href: `/${VehicleTypesPath.base}`,
        active: isActive(VehicleTypesPath.base)
      },
      {
        label: 'Countries',
        href: `/${CountriesPath.base}`,
        active: isActive(CountriesPath.base)
      }
    ];
  }, [pathname]);

  return (
    <HoneycomHeader
      brand={
        <HeaderBrand
          alt="FlixCPC Home"
          href="/"
          src={logo}
          height="30"
          width="115"
          extraClasses="header-brand"
        />
      }
      navigation={
        <HeaderNavigation aria-label="Header navigation">
          {displayNavigation ? (
            <>
              {navItems.map(navItem => (
                <NavItem
                  key={navItem.label}
                  href={navItem.href}
                  active={navItem.active}
                >
                  {navItem.label}
                </NavItem>
              ))}
            </>
          ) : (
            <></>
          )}
        </HeaderNavigation>
      }
      widgets={
        <HeaderWidgets extraClasses="header-widgets">
          <div className="flex-center">
            {displayNavigation && <LocalUnits />}
            <LoginWidget />
          </div>
        </HeaderWidgets>
      }
    />
  );
};

export default React.memo(AppHeader);

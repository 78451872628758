const base = 'partner-costs';
const steps = {
  vehicleCosts: 'vehicle-costs',
  driverCosts: 'driver-costs',
  otherCosts: 'other-costs'
};

export const PartnerCostsPath = {
  base,
  steps,
  vehicleCosts: `${base}/${steps.vehicleCosts}`,
  driverCosts: `${base}/${steps.driverCosts}`,
  otherCosts: `${base}/${steps.otherCosts}`
};

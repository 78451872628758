import {datadogRum} from '@datadog/browser-rum';
import ResizeObserver from 'resize-observer-polyfill';
import {
  DataTable,
  DataTableHead,
  DataTableRow,
  Checkbox,
  Range
} from '@flixbus/honeycomb-react';
import React, {useEffect, useRef, useState, useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {resetDataTableEntries, selectHomePageQueryParams} from '../store/slice';
import {getProductionCostEntries} from '../store/thunks';
import {getCountries} from '../../Countries/store/thunks';
import {getPartners} from '../../Partners/store/thunks';
import constants from '../constants';
import {getProductionCost} from '../../ProductionCost/store/thunks';
import {useNavigate} from 'react-router';
import {isNil} from 'ramda';
import DeleteConfirmationPopup from './DeleteConfirmationPopup';
import ViewInputCostFactorPopup from './InputCostFactors/ViewInputCostFactorPopup';
import DataTableActionButtons from './DataTableActionButtons';
import {getAnalyticsRows} from '../utils/AnalyticsRows';
import {getAnalyticsAbsoluteRows} from '../utils/AnalyticsAbsoluteRows';
import {getStandardRows} from '../utils/StandardRows';
import {useToast} from '../../Common/toasts-container';
import CloneConfirmationPopup from './CloneConfirmationPopup';
import {isNotNilOrEmpty} from 'ramda-adjunct';
import {useLocalUnitsContext} from '../../Common/LocalUnits';
import {AnalyticsModeType} from '../store/types';
import {isCalculatedSubtotalRow} from 'Pages/Home/utils';
import {ProductionCostCalculationPath} from 'Pages/ProductionCost/constants/routePaths';

const Calculations = () => {
  const dispatch = useDispatch();
  const calculatedEntries = useSelector(
    ({calculatedEntries}) => calculatedEntries
  );
  const productionCost = useSelector(({productionCost}) => productionCost);
  const partners = useSelector(({partners}) => partners);
  const countries = useSelector(({countries}) => countries);
  const queryParams = useSelector(selectHomePageQueryParams);

  const containerRef = useRef(null);
  const [scrollOffset, setScrollOffset] = useState(0);

  const [entryToView, setEntryToView] = useState(null); // input cost factors
  const [entryToDelete, setEntryToDelete] = useState(null);
  const [entryToClone, setEntryToClone] = useState(null);

  const {
    page,
    entries,
    analyticsMode,
    analyticsModeType,
    absoluteValuesEntries,
    recordsPerPage,
    isReloadData
  } = calculatedEntries;
  const navigate = useNavigate();
  const {addToast} = useToast();
  const {localUnits} = useLocalUnitsContext();

  const [rows, setRows] = useState([]);
  const [viewType, setViewType] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [calculatedSubtotal, setCalculatedSubtotal] = useState([]);

  const parsedSelectedRows = useMemo(
    () => selectedRows.map(JSON.parse),
    [selectedRows]
  );

  // Entries resulting from specific query are available
  const analyticsEntriesAreReady = entries.some(
    entry => constants.uniqueAnalyticsEntriesIdentifier in entry
  );
  const standardEntriesAreReady = entries.some(
    entry => constants.uniqueStandardEntriesIdentifier in entry
  );

  const clearSelection = useCallback(() => {
    setCalculatedSubtotal([]);
    setSelectedRows([]);
  }, []);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getPartners());
  }, [dispatch]);

  // Handles Range component visibility based on the table width
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (containerRef.current) {
        const {scrollWidth, clientWidth} = containerRef.current;
        setScrollOffset(scrollWidth - clientWidth);
      }
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  // Fetches entries on mount and every time filters change or analytics mode is toggled
  useEffect(() => {
    clearSelection();
    dispatch(resetDataTableEntries());
    dispatch(
      getProductionCostEntries(0, queryParams, analyticsMode, recordsPerPage)
    );
  }, [
    clearSelection,
    dispatch,
    queryParams,
    analyticsMode,
    recordsPerPage,
    localUnits?.unitsSystem
  ]);

  useEffect(() => {
    if (isReloadData) {
      clearSelection();
      dispatch(
        getProductionCostEntries(
          page,
          queryParams,
          analyticsMode,
          recordsPerPage
        )
      );
    }
  }, [
    isReloadData,
    clearSelection,
    dispatch,
    page,
    queryParams,
    analyticsMode,
    recordsPerPage
  ]);

  // Navigates to production-cost-calculation when production cost data is ready for edit mode
  useEffect(() => {
    if (productionCost.id && !isNil(productionCost.busScheduleValues)) {
      navigate(ProductionCostCalculationPath.edit.dataEntry);
    }
  }, [productionCost, navigate]);

  // Clear selection if not analytics mode
  useEffect(() => {
    if (!analyticsMode) clearSelection();
  }, [analyticsMode, clearSelection]);

  const handleScrollChange = event => {
    containerRef.current.scrollLeft = event.target.value;
  };

  const handleEditClick = useCallback(
    entry => dispatch(getProductionCost(entry.id)),
    [dispatch]
  );

  useEffect(() => {
    datadogRum.addAction('cpc.home-page', {
      viewType: analyticsModeType
    });
  }, [analyticsModeType]);

  useEffect(() => {
    switch (analyticsModeType) {
      case AnalyticsModeType.Analytics:
        setRows(
          getAnalyticsRows(
            analyticsEntriesAreReady,
            entries,
            calculatedSubtotal,
            partners,
            setEntryToView
          )
        );
        setViewType('analyticsView');
        break;

      case AnalyticsModeType.AnalyticsAbsolute:
        setRows(
          getAnalyticsAbsoluteRows(
            analyticsEntriesAreReady,
            absoluteValuesEntries,
            calculatedSubtotal,
            partners,
            setEntryToView
          )
        );
        setViewType('analyticsAbsoluteView');
        break;

      default:
        setRows(
          getStandardRows(
            partners,
            countries,
            standardEntriesAreReady,
            entries,
            handleEditClick,
            setEntryToDelete,
            setEntryToClone
          )
        );
        setViewType('standardView');
    }
  }, [
    absoluteValuesEntries,
    analyticsEntriesAreReady,
    analyticsModeType,
    calculatedSubtotal,
    countries,
    entries,
    handleEditClick,
    partners,
    standardEntriesAreReady
  ]);

  const areAllSelected = useMemo(
    () =>
      rows
        .filter(row => !isCalculatedSubtotalRow(row))
        .every(row => selectedRows.includes(row.rowIdStringified)),
    [rows, selectedRows]
  );

  const handleSelectAll = useCallback(() => {
    if (areAllSelected) {
      const toUnselect = rows.map(row => row.rowIdStringified);
      const updatedSelectedRows = selectedRows.filter(
        row => !toUnselect.includes(row)
      );
      setSelectedRows(updatedSelectedRows);
    } else {
      const toSelect = rows
        .filter(row => !selectedRows.includes(row.rowIdStringified))
        .filter(row => !isCalculatedSubtotalRow(row))
        .map(row => row.rowIdStringified);
      const updatedSelectedRows = [...selectedRows, ...toSelect];
      setSelectedRows(updatedSelectedRows);
    }
  }, [rows, selectedRows, areAllSelected]);

  const handleSelectOne = useCallback(
    event => {
      const rowIdStringified = event.target.value;
      const updatedSelectedRows = selectedRows.includes(rowIdStringified)
        ? selectedRows.filter(selectedRow => selectedRow !== rowIdStringified)
        : [...selectedRows, rowIdStringified];
      setSelectedRows(updatedSelectedRows);
    },
    [selectedRows]
  );

  const headers = useMemo(
    () => constants.headers(localUnits)[viewType] ?? [],
    [localUnits, viewType]
  );
  const tableHeaders = useMemo(
    () => (
      <DataTableHead
        checkbox={
          <Checkbox
            id="select-all-calculations-checkbox"
            value="select-all"
            checked={areAllSelected}
            onChange={handleSelectAll}
            small
            aria-label="Select All"
          />
        }
      >
        {headers.map(header => (
          <th key={header.key} scope="col">
            {header.title}
          </th>
        ))}
      </DataTableHead>
    ),
    [headers, handleSelectAll, areAllSelected]
  );

  const tableRows = useMemo(() => {
    const getCheckbox = row => (
      <Checkbox
        id={`select-${row.rowIdStringified}-calculation-checkbox`}
        value={row.rowIdStringified}
        checked={selectedRows.includes(row.rowIdStringified)}
        onChange={handleSelectOne}
        aria-label="Select Calculation"
        small
      />
    );

    return rows.map(row => {
      const isSubtotalRow = isCalculatedSubtotalRow(row);
      return (
        <DataTableRow
          key={row.rowIdStringified}
          checkbox={isSubtotalRow ? <></> : getCheckbox(row)}
          extraClasses={isSubtotalRow ? 'calculated-subtotal-row' : ''}
        >
          {headers.map(header => (
            <td key={header.key}>{row[header.key]}</td>
          ))}
        </DataTableRow>
      );
    });
  }, [headers, rows, selectedRows, handleSelectOne]);

  return (
    <>
      <DataTableActionButtons
        selectedRows={parsedSelectedRows}
        setCalculatedSubtotal={setCalculatedSubtotal}
      />
      <div ref={containerRef} className="calculations-container">
        <CloneConfirmationPopup
          entryToClone={entryToClone}
          setEntryToClone={setEntryToClone}
          addToast={addToast}
        />
        <DeleteConfirmationPopup
          entryToDelete={entryToDelete}
          setEntryToDelete={setEntryToDelete}
        />
        {entryToView && (
          <ViewInputCostFactorPopup
            entryToView={entryToView}
            setEntryToView={setEntryToView}
          />
        )}
        {rows && rows.length > 0 && (
          <div className="table-container">
            {scrollOffset !== 0 && (
              <Range
                id="inner-scroll-bar"
                aria-label="inner-scroll-bar"
                max={scrollOffset}
                defaultValue={0}
                onChange={handleScrollChange}
                extraClasses="table-scroll"
              />
            )}

            <h4>
              {selectedRows.length > 0
                ? `${selectedRows.length} selected row${
                    selectedRows.length === 1 ? '' : 's'
                  }`
                : 'No rows selected'}
            </h4>

            <DataTable
              scrollable="sticky-header"
              extraClasses={`calculations-table
              ${
                isNotNilOrEmpty(calculatedSubtotal)
                  ? 'calculated-table-data calculated-table-data-' +
                    calculatedSubtotal.subtotals.length
                  : ''
              } `}
              height={constants.tableHeight}
              headers={tableHeaders}
            >
              {tableRows}
            </DataTable>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(Calculations);

import React, {useCallback, useMemo} from 'react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {AdditionalInfrastructureCostProps} from './AdditionalInfrastructureCost.types';
import styles from './AdditionalInfrastructureCost.module.scss';

const AdditionalInfrastructureCost: React.FC<
  AdditionalInfrastructureCostProps
> = ({value, entryId, currency}) => {
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-additional-infrastructure-cost`;

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const additionalCostPerTrip =
        validateInputNumber(editedValue, value ? value.toString() : '') ??
        undefined;

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {additionalCostPerTrip}
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (typeof value === 'number' ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="additional-infrastructure-cost"
        label={`Add. infrastructure cost`}
        value={formattedValue}
        tooltipContent={fieldInfoContent.addInfrastructureCost}
        handleInputChange={handleInputChange}
        inlineLabelLeft={`${currency}/trip`}
      />
    </span>
  );
};

export default React.memo(AdditionalInfrastructureCost);

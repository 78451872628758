import {gql} from '@apollo/client';

const SEARCH_PLANR_SCHEDULES = gql`
  query searchPlanrSchedules(
    $lineUuid: ID!
    $startDate: Date!
    $endDate: Date!
    $scheduleStatus: PlanrScheduleStatus
    $scheduleType: PlanrScheduleType
    $scheduleWorkflowType: PlanrScheduleWorkflowType
  ) {
    searchPlanrSchedules(
      input: {
        lineUuid: $lineUuid
        startDate: $startDate
        endDate: $endDate
        scheduleStatus: $scheduleStatus
        scheduleType: $scheduleType
        scheduleWorkflowType: $scheduleWorkflowType
      }
    ) {
      uuid
      lineUuid
      name
      description
      status
      type
      workflowType
      startDate
      endDate
    }
  }
`;

export default SEARCH_PLANR_SCHEDULES;

const base = 'regional-costs';
const steps = {
  vehicleCosts: 'vehicle-costs',
  driverCosts: 'driver-costs',
  otherCosts: 'other-costs',
  emptyDrivingDefaults: 'empty-driving-defaults',
  driverConceptTypes: 'driver-concept-types',
  driverSalaryPeriods: 'driver-salary-periods'
};

export const RegionalCostsPath = {
  base,
  steps,
  vehicleCosts: `${base}/${steps.vehicleCosts}`,
  driverCosts: `${base}/${steps.driverCosts}`,
  otherCosts: `${base}/${steps.otherCosts}`,
  emptyDrivingDefaults: `${base}/${steps.emptyDrivingDefaults}`,
  driverConceptTypes: `${base}/${steps.driverConceptTypes}`,
  driverSalaryPeriods: `${base}/${steps.driverSalaryPeriods}`
};
